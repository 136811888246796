/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;


    @font-face {
        font-family: 'RotisLight';
        src: url('./components/fonts/RotisLight.ttf');
        font-style: normal;
    }
    @font-face {
        font-family: 'RotisBold';
        src: url('./components/fonts/RotisBold.ttf');
        font-style: normal;
        font-weight: bold;
    }

    @layer base {
      body {
        -moz-osx-font-smoothing: grayscale;
        -moz-font-feature-settings: "liga", "kern";
        text-rendering: optimizelegibility;
        overflow-x: hidden;
      }

      [aria-current="page"] {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
        }

      .mainwrapper { margin-top: 0px!important; }

      .leading-list li span {
        font-size: 1em;
        padding-left: 0px;
        line-height: 1.1em;
      }
      .leading-list {
        display: block;
        font-size: 1.2em;
        line-height: 1.85em;
        padding-bottom: 10px;
        text-transform: uppercase;
      }
      .textwrapper .leading-list-wrapper:nth-child(4) {
        margin-top: 20px;
      }

      .leading-text {
        display: block;
        font-size: 1.5em;
        line-height: 1.4em;
        padding-bottom: 10px;
      }
      @media only screen and (max-width: 1024px) { .leadtext { margin-bottom: 20px; } }

   .sidebtns {
        bottom: 25px;
        margin-right: -15px;
   }

   .homeimages {
         background-position: center center!important;
         height: 330px;
   }
   .homeimages .text-xl { font-size: 1.75em; }

   .contactbtns { justify-content: center; margin: 10px 0px 40px 0px; }
   .contactbtns a, .contactbtns img { display: inline-block; width: 60px; height:60px; }
 .contactbtns a { margin: 10px 10px 10px 10px; }

   .mobilnav li li:first-child a, .normnav li li:first-child a { border-top: 1px dotted rgba(87, 87, 86, var(--tw-text-opacity)); margin-top: 10px; }
   .mobilnav li li, .normnav li li { display: block; padding: 0px; margin-left: 20px; margin-right: 20px; width: calc(100% - 40px); }
   .mobilnav li li a, .normnav li li a { display: block; width: 100%; padding: 6px 0px; margin: 0px 0px 0px 0px; border-bottom: 1px dotted rgba(87, 87, 86, var(--tw-text-opacity)); }
   .normnav ul { padding-bottom: 20px; margin-left: -20px; }
   .normnav li li:first-child a { margin-top: 7px; }
   .normnav a, .normnav span { }
   .normnav { position: relative; }

   .navbtn { position: absolute; z-index: 2; margin-right: 20px; right: 0px; width: 45px; height: 45px; }
   .navbtn svg { width: 45px; height: 45px; }
   .halfrow { width: 50%; }

@media only screen and (max-width: 1024px) {

  .navbtn {
    margin-top: 18px;
  }

  .sidebtns { display: none; }

  .halfrow { width: 100%; }

  .mobilnav.flex { position: fixed; top: 0px;  left: 0px; background: #FFF; z-index: 1; width: 100%; height: 100vh; padding: 80px 50px 80px 50px; }
  .mobilnav.flex, .mobilnav .inline-block, .mobilnav ul, .mobilnav span { display: block; width: 100%; }
  .mobilnav li { width: 100%; display: block; }
  .mobilnav li.pr-2 { padding: 0px 0px 12px 0px; }
  .mobilnav li.hidden, .mobilnav li.hidden .inline-block{ display: none; }
  .mobilnav li.pr-2.hidden { padding: 0px 0px; }
  .mobilnav li.pr-2:first-child { display: none; }
  .mobilnav li ul li a.text-base { font-size: 1.05em; line-height: 20pt; }
  .mobilnav li span.text-base, .mobilnav li a.text-base { font-size: 1.3em; }

  .homeimages {
        height: 300px;
  }

  .logomobil {
  width: 185px;
  height:36px;
  margin: 26px 0px 12px 0px;
   }


  }

@media only screen and (max-width: 600px) {

  .homeimages {
        height: 280px;
  }

.sidebtns {
  bottom: 10px;
}


h2.py-4 { padding-top: 0px; }

.homeimages div.w-teasertext { padding: 12px 20px; }

}

@media only screen and (max-width: 480px) {

  .homeimages {
        height: 240px;
  }


}

@media only screen and (max-width: 320px) {

  .homeimages {
        height: 180px;
  }


}

    }
